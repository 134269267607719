export function rpnChatExample() {
  const element = document.querySelector('#example-wrapper__right-panel-content');

  //Сделаем все вокрун чата примерно как в макете РПН
  const innerElement = document.createElement('div');
  innerElement.style.setProperty('height', '100%');
  innerElement.style.setProperty('background-color', '#fff');
  innerElement.style.setProperty('padding', '32px 64px');
  innerElement.style.setProperty('box-sizing', 'border-box');
  element.appendChild(innerElement);

  //Инициализация чата
  const chat = new window['b3-chat']({
    //Сообщение, которые будет отправлено после инициализации чата
    initialRequestMessage: 'Привет! Что ты умеешь?',

    //Авторизация
    xAccessToken:
      'v4.public.eyJpZCI6MSwiZXNpYV9vaWQiOi0yLCJpbm4iOiIwMDAwMDAwMDAwMDciLCJzbmlscyI6IjEwMTEyMzE0MiIsImVtYWlsIjoiYWRtaW5AbG9jYWxob3N0IiwicGhvbmUiOm51bGwsIm5hbWUiOiJcdTA0MTBcdTA0MzRcdTA0M2NcdTA0MzhcdTA0M2RcdTA0MzhcdTA0NDFcdTA0NDJcdTA0NDBcdTA0MzBcdTA0NDJcdTA0M2VcdTA0NDAgXHUwNDFiXHUwNDM4XHUwNDQ3XHUwNDNkXHUwNDNlXHUwNDMzXHUwNDNlIFx1MDQxYVx1MDQzMFx1MDQzMVx1MDQzOFx1MDQzZFx1MDQzNVx1MDQ0Mlx1MDQzMCIsIm9yZ19lc2lhX29pZCI6MTA2NjY1MzA2MSwib3JnX2lkIjo5ODI1NSwib3JnX25hbWUiOiJcdTA0MTBcdTA0M2FcdTA0NDZcdTA0MzhcdTA0M2VcdTA0M2RcdTA0MzVcdTA0NDBcdTA0M2RcdTA0M2VcdTA0MzUgXHUwNDNlXHUwNDMxXHUwNDQ5XHUwNDM1XHUwNDQxXHUwNDQyXHUwNDMyXHUwNDNlIFwiXHUwNDJkXHUwNDQ0XHUwNDQ0XHUwNDM1XHUwNDNhXHUwNDQyXCIiLCJvcmdfaW5uIjoiNTQzNjEwMjg4MSIsIm9yZ19vZ3JuIjoiMTAyNTQwNTQyNjkyNSIsIm9yZ19rcHAiOiI1NDM2MDEwMDEiLCJwcm9maWxlX2lkIjoxNDc0NTEyLCJzdWIiOiIxOjk4MjU1IiwiYXVkIjoic3ZjIiwiaXNzIjoibWFpbi1hcHAiLCJpYXQiOiIyMDI0LTEwLTEwVDE4OjIyOjM0KzAzOjAwIiwibmJmIjoiMjAyNC0xMC0xMFQxODoyMjozNCswMzowMCIsImp0aSI6ImRhYThmODMyLWZmNDMtNDEzMS04MjZlLTU2YTUzN2E2NDU0OSIsImV4cCI6IjIwMjUtMDgtMTBUMTg6MjI6MzQrMDM6MDAifcdpziXm56QM1p6OLLU0FItrWM7cSB227xiIPWLwC966QqPQV0JFtuOOOr9LGAMgLIHw4VOF_RYc4sHlmUdhmAM.eyJraWQiOiJiNmVlYTAwMzQ4NjhiYTc5NWFlMzQwZjZlZGMzOTZkNyJ9',
    backendApiKey: 'aba787cc4924293a5027f3d4a7895ef7b7853c46',

    //Бэкэнд
    backendUrl: 'https://mls.big3.ru/',
    backendPath: '/api/v1/chat/history/',
    options: {
      manager_id: 6,
      xsrf_token:
        'eyJpdiI6Ik9RQXF1SFNRbThiWVRlQmk5RWxHRXc9PSIsInZhbHVlIjoidjR6UnFHTWF4a1RnRFFORlJ4RmN3QUlYK0tHTmxSUzdSK0hIY1pTQng1OWtGaEQ4U2krUGJyN1BmM24xc3FHUVlVdXNLUUVhNTZ2T1Q0ZW9DcHBYQy95UXV6TkltZEhyOGVyNjZsZTFDM0NweG0xRFJrUHd2TytFWS92anpUL24iLCJtYWMiOiJhNTc4NjQyZWJlNDRkNjkwY2VkMDA1OWM3YTdkNWFjMTFhMDliNWJkMDM4NjFkZTZmZGQyODkwYzM3NDM3NWI2IiwidGFnIjoiIn0=',
      session_id:
        'eyJpdiI6IlpzcjkxR3RZSFoxREx1WUphZjNVY3c9PSIsInZhbHVlIjoiZ0ptLy85bFNoRzdEV09QdnJrSGU5N0xtUmIrU0I5QXFrVGFEMXNTNTBoanZmMHA1NkNmZ1BpTU82Y1hTclQ5Yk9xV2ExODhrNG81c1h4WW9aM04xMFpLWFYyNzA5bVEzekhyMFhYMG9ocGJ4QXRMTm5ZZkVsa1NmSlZhcUo4eHgiLCJtYWMiOiI2YzNmZTAxZGJlMjJiMTIyMjZhNjMyNjQ2NmU5MTFjMzRhMzFlNTcxNGZiNzM1MWE1ZGMyNWQwMjVjN2Y1MjQxIiwidGFnIjoiIn0='
    },

    //История сообщений
    loadHistory: true,
    messageLoadCount: 5,

    //Быстрые действия
    hintConfig: {
      path: '/api/v1/recognize/datasets/',
      pipeline: 'quick_actions_temp',
      hintsCount: 5
    },

    //Стилизация
    cssClass: 'rpn-chat',
    imagesUrl: 'https://205129.selcdn.ru/b3-gis/lib-chat/images/',

    //Колбэк на события чата
    onEvent: (eventType, eventData) => {
      window.console.log(eventType, eventData);
    }
  });

  //Добавление чата в разметку
  innerElement.appendChild(chat.render());
}
