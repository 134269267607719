export function districtMapExample() {
  const element = document.querySelector('#example-wrapper__right-panel-content');
  const b3Gis = b3GisLib.init(element, {
    backendUser: 'user',
    backendPassword: 'login',
    layerTreeVisible: false,
    mapName: 'demo-district-map',
    backendUrl: 'https://gis.big3.ru/',
    onLayerEdit: (eventData) => {
      const layerFeatureCollection = b3Gis.getLayerEditGeoJson('district');
      if (layerFeatureCollection.features.length === 1) {
        window.console.log('Одна геометрия, можно сохранять в БД', layerFeatureCollection.features[0]);
      } else if (layerFeatureCollection.features.length === 0) {
        window.console.log('Нет геометрий. В БД сохраним null, значит у объекта нет геометрии');
      } else {
        window.console.log('Несколько геометрий. Какую назначить объекту непонятно. Удалите все кроме одной нужной.');
      }
    }
  });

  b3Gis.setGisState({
    editingLayerId: 'district'
  });
}
