import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { basicSetup, EditorView } from 'codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { init } from '@mm/b3-gis-types/dist/b3-gis';

import { loadScriptByUrl, loadStyle } from '../load-util';

type B3Gis = {
  init: typeof init;
};

const GIS_JS_FILE_NAME = 'b3-gis.umd.js';
const GIS_CSS_FILE_NAME = 'style.css';
const GIS_DEFAULT_LIB_URL = 'https://gis.big3.ru/lib/';

const CHAT_JS_FILE_NAME = 'b3-chat.umd.js';
const CHAT_CSS_FILE_NAME = 'style.css';
const CHAT_DEFAULT_LIB_URL = 'https://205129.selcdn.ru/b3-gis/lib-chat/';

@Component({
  selector: 'app-example-wrapper',
  templateUrl: './example-wrapper.component.html',
  styleUrls: ['./example-wrapper.component.scss']
})
export class ExampleWrapperComponent {
  @ViewChild('codemirrorElement') codemirrorElement: ElementRef;
  codemirrorView: EditorView;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit() {
    const routeData = this.activatedRoute.snapshot.data as any;

    this.codemirrorView = new EditorView({
      doc: routeData.exampleCode as string,
      extensions: [basicSetup, javascript()],
      parent: this.codemirrorElement.nativeElement
    });

    switch (routeData.libType) {
      case 'chat':
        this.applyChatLib();
        break;
      default:
        this.applyGisLib();
        break;
    }
  }

  applyCode() {
    const routeData = this.activatedRoute.snapshot.data as any;
    switch (routeData.libType) {
      case 'chat':
        this.applyChatCode();
        break;
      default:
        this.applyGisCode();
        break;
    }
  }

  applyGisLib() {
    if ((window as unknown as any).b3Gis) {
      this.applyGisCode();
    } else {
      const libUrl = this.activatedRoute.snapshot.queryParamMap.get('lib_url') || GIS_DEFAULT_LIB_URL;
      loadStyle(libUrl, GIS_CSS_FILE_NAME);
      loadScriptByUrl(`${libUrl}${GIS_JS_FILE_NAME}`, (scriptref, id) => {
        this.applyGisCode();
      });
    }
  }

  applyGisCode() {
    const b3GisLib = (window as unknown as any).b3Gis as B3Gis;
    const codeStr = this.getCodemirrorValue();
    const fn = new Function('{ b3GisLib}', codeStr);
    return fn({
      b3GisLib
    });
  }

  applyChatLib() {
    if ((window as unknown as any)['b3-chat']) {
      this.applyChatCode();
    } else {
      const libUrl = this.activatedRoute.snapshot.queryParamMap.get('lib_url') || CHAT_DEFAULT_LIB_URL;
      loadStyle(libUrl, CHAT_CSS_FILE_NAME);
      loadScriptByUrl(`${libUrl}${CHAT_JS_FILE_NAME}`, (scriptref, id) => {
        this.applyChatCode();
      });
    }
  }

  applyChatCode() {
    const codeStr = this.getCodemirrorValue();
    const fn = new Function('', codeStr);
    return fn();
  }

  getCodemirrorValue() {
    if (!this.codemirrorView) {
      return '';
    }
    return this.codemirrorView.state.doc.toString();
  }
}
