import { Component, ElementRef, ViewChild } from '@angular/core';
//import { init } from '@mm/b3-gis/dist/b3-gis';
//import GisMouseEvent from '@mm/b3-gis/dist/types/GisMouseEvent';

@Component({
  selector: 'app-map-libre-gl-map',
  templateUrl: './map-libre-gl-map.component.html',
  styleUrls: ['./map-libre-gl-map.component.scss']
})
export class MapLibreGlMapComponent {
  map: null;
  @ViewChild('mapElement') mapElement: ElementRef;

  ngAfterViewInit() {
    /*
    console.log(this.mapElement);
    this.mapElement.nativeElement.classList.add('dx-viewport');
    const b3Gis = init(this.mapElement.nativeElement, {
      controls: {
        goToGis: false,
        identification: false,
        timeline: false,

        toolbarButtons: []
      },
      layerTreeVisible: false,
      mapName: 'ecomonitoring',
      backendUrl: 'https://gis.big3.ru/',
      backendUser: 'user',
      backendPassword: 'password',
      onClick: (eventData: GisMouseEvent) => {
        window.console.log(eventData);
      }
    });

    window.setTimeout(() => {
      b3Gis.setGisState({
        controls: {
          goToGis: false,
          identification: false,
          timeline: false,
          toolbarButtons: ['layerTree', 'snapshot-for-report']
        },
        zoom: 14,
        center: [45.060787, 49.142603],
        layers: {
          '338': {
            params: {
              object_id: { value: 13615 }
            },
            fit: {
              animate: false
            }
          },
          '339': {
            params: {
              object_id: { value: 13615 }
            }
          }
        },
        reportConfigs: {
          report1: {
            name: 'Подготовка отчета',
            patchEntityType: 'wf__accumulated_harm_object__accumulated_harm_objects',
            patchEntityId: 13615,
            snapshotUploadUrl: 'https://gronvos.ru/api/storage/upload/',
            patchBaseUrl: 'https://gronvos.ru/api',
            patchSuccessMessage: 'Привязка снимка сохранена',
            auth: {
              token: 'ANY-JWT'
            },
            snapshotConfigs: {
              uploaded_file: {
                fileName: 'geomap-screenshot.jpg',
                fileFormat: 'image/jpeg',
                code: 'uploaded_file',
                name: 'Расположение контейнера на карте',
                width: 700,
                height: 400,
                patchParamMap: {
                  map_picture: 'id'
                }
              }
            }
          }
        }
      });
    }, 300);
    */
  }
}
