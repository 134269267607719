import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import ChatConfig from '@b3chat/b3chat-front-types/dist/types/ChatConfig';
import { loadScriptByUrl, loadStyle } from '../load-util';

/*
type B3Gis = {
  init: typeof init;
};
*/

const JS_FILE_NAME = 'b3-chat.umd.js';
const CSS_FILE_NAME = 'style.css';
const DEFAULT_LIB_URL = 'https://205129.selcdn.ru/b3-gis/lib-chat/';

@Component({
  selector: 'app-b3chat-lazy-load',
  templateUrl: './b3chat-lazy-load.component.html',
  styleUrls: ['./b3chat-lazy-load.component.scss']
})
export class B3chatLazyLoadComponent {
  count = 0;
  map: null;
  @ViewChild('chatContainer') chatContainer: ElementRef;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngAfterViewInit() {
    const libUrl = this.activatedRoute.snapshot.queryParamMap.get('lib_url') || DEFAULT_LIB_URL;

    if (!(window as unknown as any).b3Chat) {
      loadStyle(libUrl, CSS_FILE_NAME);
      loadScriptByUrl(`${libUrl}${JS_FILE_NAME}`, (scriptref, id) => {
        const windowAsAny = window as unknown as any;
        const ChatClass = windowAsAny['b3-chat'];
        windowAsAny.createChat = (options: ChatConfig) => {
          const chat = new ChatClass(options);
          this.chatContainer.nativeElement.appendChild(chat.render());
        };

        window.console.log('B3Chat script loaded');
      });
    }
  }
}
